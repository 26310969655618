import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const Logo3 = (props: IIconProps) => {
  const { width = 176, height = 80, className } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 176 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M61.8089 38.7017C64.7186 37.2354 66.3879 34.6702 66.3879 31.6571C66.3879 29.3715 65.6566 27.5797 64.2094 26.3125C62.4483 24.7734 59.6113 24 55.7904 24C52.6165 24 48.6157 24.536 47 24.7657V47.7141H53.3171V39.9115H56.0163L60.4919 47.7141H67.4943L61.3495 38.9276L61.8051 38.7017H61.8089ZM55.6449 36.1059H53.321V28.6173L53.566 28.5713C54.0943 28.4794 54.8868 28.4182 55.5262 28.4182C58.2713 28.4182 59.7836 29.785 59.7836 32.2582C59.7836 34.4596 58.0071 36.1098 55.6449 36.1098V36.1059Z"
        fill="white"
      />
      <path
        d="M90.0098 37.7905C90.0175 34.4749 88.8689 31.6992 86.7096 29.762C84.7226 27.9778 81.9584 26.9939 78.93 26.9939C75.9016 26.9939 73.2024 27.9778 71.2231 29.762C69.0638 31.7107 67.9229 34.4864 67.9229 37.7905C67.9229 41.0945 69.0638 43.8626 71.2231 45.8113C73.2024 47.5954 75.936 48.5679 78.93 48.5679C81.9239 48.5679 84.7188 47.5839 86.7096 45.8113C88.8689 43.874 90.0098 41.0945 90.0098 37.7905ZM81.7937 42.4881C81.1008 43.4338 80.1321 43.9353 78.9759 43.9353C77.8197 43.9353 76.8587 43.4338 76.1658 42.4881C75.3924 41.4238 74.998 39.8387 74.998 37.7905C74.998 35.7422 75.3886 34.1495 76.1658 33.0851C76.8587 32.128 77.8235 31.6303 78.9759 31.6303C80.1283 31.6303 81.1008 32.1318 81.7937 33.0851C82.5671 34.1495 82.9615 35.7345 82.9615 37.7905C82.9615 39.8464 82.5709 41.4238 81.7937 42.4881Z"
        fill="white"
      />
      <path
        d="M126.01 42.5724C123.785 42.5724 123.33 41.4889 123.33 39.8695V33.426H127.897V28.7475H123.33V24.1647L116.775 26.1479L116.802 41.7493C116.802 43.6865 117.396 45.3443 118.506 46.5388C119.647 47.7601 121.251 48.4033 123.165 48.4033C125.443 48.4033 127.79 48.0396 128.981 47.6644V42.1551C128.269 42.3657 127.258 42.5648 126.01 42.5648V42.5724Z"
        fill="white"
      />
      <path
        d="M111.148 29.762C109.161 27.9778 106.397 26.9939 103.368 26.9939C100.34 26.9939 97.6409 27.9778 95.6616 29.762C93.5022 31.7107 92.3613 34.4864 92.3613 37.7905C92.3613 41.0945 93.5022 43.8626 95.6616 45.8113C97.6409 47.5954 100.375 48.5679 103.368 48.5679C106.362 48.5679 109.157 47.5839 111.148 45.8113C113.311 43.874 114.448 41.0945 114.448 37.7905C114.456 34.4749 113.307 31.6992 111.148 29.762ZM106.232 42.4881C105.539 43.4338 104.574 43.9353 103.414 43.9353C102.254 43.9353 101.297 43.4338 100.604 42.4881C99.8309 41.4238 99.4365 39.8387 99.4365 37.7905C99.4365 35.7422 99.827 34.1495 100.604 33.0851C101.297 32.128 102.262 31.6303 103.414 31.6303C104.567 31.6303 105.539 32.1318 106.232 33.0851C107.006 34.1495 107.4 35.7345 107.4 37.7905C107.4 39.8464 107.009 41.4238 106.232 42.4881Z"
        fill="white"
      />
      <path d="M80.8558 51.9064H79.7876V59.843H80.8558V51.9064Z" fill="white" />
      <path
        d="M86.1356 53.8322C85.301 53.8322 84.4702 54.3146 83.9227 54.8583V53.9853H82.8813V59.8392H83.9227V55.7273C84.3515 55.2832 85.0789 54.7664 85.7719 54.7664C86.3309 54.7664 86.6563 55.0918 86.6563 55.7924V59.8392H87.6977V55.467C87.6977 54.284 86.9549 53.8284 86.1356 53.8284V53.8322Z"
        fill="white"
      />
      <path
        d="M91.4763 56.382C90.8638 56.1867 90.2933 56.0029 90.2933 55.444C90.2933 54.9348 90.7604 54.7166 91.3079 54.7166C91.9205 54.7166 92.4909 54.9386 92.8049 55.1607L93.1188 54.326C92.847 54.1308 92.2076 53.8322 91.3768 53.8322C90.2206 53.8322 89.3324 54.4064 89.3324 55.5895C89.3324 56.7725 90.3852 57.1362 91.2466 57.3851C91.8324 57.5803 92.3378 57.7641 92.3378 58.2848C92.3378 58.8055 91.8554 59.1041 91.2045 59.1041C90.5001 59.1041 89.8262 58.7404 89.4625 58.4532L89.0835 59.2457C89.4625 59.6095 90.255 60 91.216 60C92.3875 60 93.337 59.3759 93.337 58.101C93.337 56.9563 92.399 56.6576 91.4763 56.382Z"
        fill="white"
      />
      <path
        d="M98.5052 58.0972C98.0764 58.5413 97.349 59.0582 96.656 59.0582C96.097 59.0582 95.7716 58.7328 95.7716 58.0321V53.9854H94.7188V58.3576C94.7188 59.5406 95.473 59.9962 96.2923 59.9962C97.1116 59.9962 97.9577 59.5138 98.5052 58.9701V59.8431H99.5466V53.9892H98.5052V58.1011V58.0972Z"
        fill="white"
      />
      <path
        d="M102.518 55.3904V53.9853H101.477V59.8392H102.518V56.2212C103.027 55.31 103.977 55.0114 104.585 54.8812V53.8284C103.728 53.8284 102.855 54.4677 102.518 55.3904Z"
        fill="white"
      />
      <path
        d="M107.94 53.8322C107.094 53.8322 106.198 54.104 105.677 54.4447L105.926 55.2258C106.316 55.0305 106.822 54.7702 107.668 54.7702C108.162 54.7702 108.736 54.9003 108.736 55.6928V56.4739L107.813 56.5504C106.202 56.6921 105.378 57.343 105.378 58.4762C105.378 59.5176 106.148 60 107.017 60C107.695 60 108.28 59.7128 108.748 59.1424L108.786 59.8468H109.774V55.4363C109.774 54.2801 108.993 53.836 107.94 53.836V53.8322ZM108.732 58.346C108.25 58.9701 107.706 59.1385 107.274 59.1385C106.699 59.1385 106.416 58.8399 106.416 58.3575C106.416 57.7335 106.898 57.4463 107.549 57.3698L108.732 57.2281V58.346Z"
        fill="white"
      />
      <path
        d="M114.954 53.8322C114.119 53.8322 113.288 54.3146 112.741 54.8583V53.9853H111.699V59.8392H112.741V55.7273C113.169 55.2832 113.897 54.7664 114.59 54.7664C115.149 54.7664 115.474 55.0918 115.474 55.7924V59.8392H116.516V55.467C116.516 54.284 115.773 53.8284 114.954 53.8284V53.8322Z"
        fill="white"
      />
      <path
        d="M121.021 59.062C120.072 59.062 119.188 58.4111 119.188 56.9027C119.188 55.3942 120.072 54.7434 121.021 54.7434C121.489 54.7434 121.933 54.8735 122.285 55.0956L122.599 54.2227C122.17 54.0159 121.623 53.8322 120.987 53.8322C119.322 53.8322 118.085 54.9884 118.085 56.9027C118.085 58.8169 119.322 59.9847 120.987 59.9847C121.78 59.9847 122.327 59.7128 122.718 59.464L122.381 58.6447C122.028 58.8667 121.561 59.062 121.029 59.062H121.021Z"
        fill="white"
      />
      <path
        d="M126.21 53.8322C124.674 53.8322 123.453 55.0152 123.453 56.9409C123.453 58.8667 124.598 60 126.34 60C127.289 60 127.994 59.6477 128.449 59.284L128.124 58.5298C127.76 58.8016 127.213 59.062 126.524 59.062C125.417 59.062 124.56 58.3996 124.544 56.9295H128.603V56.2671C128.603 54.9922 127.756 53.8322 126.21 53.8322ZM124.621 56.137C124.828 55.1492 125.44 54.6783 126.183 54.6783C126.991 54.6783 127.458 55.1722 127.576 56.137H124.625H124.621Z"
        fill="white"
      />
    </svg>
  );
};

export default React.memo(Logo3);
