import React from 'react';

import Backdrop from '../Backdrop/Backdrop';

import style from './ModalVideo.scss';

import cn from 'classnames';

export interface IModalVideoProps {
  onClose: () => void;
  className?: string;
  children: React.ReactNode;
  isOpen: boolean;
}

export default React.memo(function StyledModalVideo(props: IModalVideoProps) {
  const { children, isOpen = false, onClose } = props;

  return (
    <Backdrop className={style.backdrop} onOutsideClick={onClose} isOpen={isOpen}>
      <div className={cn(style.modalVideo, { [style.isOpen]: isOpen })}>{children}</div>
    </Backdrop>
  );
});
