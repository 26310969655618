import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const Logo4 = (props: IIconProps) => {
  const { width = 176, height = 80, className } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 176 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M90.3942 31.4977C90.4118 31.8353 90.3294 32.1706 90.1574 32.4616C89.9853 32.7526 89.7313 32.9864 89.427 33.1338C89.1227 33.2811 88.7817 33.3354 88.4468 33.2898C88.1118 33.2443 87.7977 33.1009 87.5438 32.8777C87.2899 32.6545 87.1074 32.3614 87.0193 32.035C86.9312 31.7086 86.9414 31.3635 87.0485 31.0429C87.1556 30.7222 87.3549 30.4403 87.6214 30.2324C87.888 30.0244 88.21 29.8998 88.547 29.874H88.6243C89.0521 29.8548 89.4705 30.0028 89.7912 30.2866C90.1119 30.5704 90.3096 30.9677 90.3426 31.3946L90.3942 31.4977Z"
        fill="white"
      />
      <path
        d="M175.998 35.7414V48.7573H173.317V46.5837C172.839 47.3533 172.168 47.9848 171.371 48.4157C170.574 48.8467 169.678 49.0621 168.772 49.0408C165.336 49.0408 162.758 46.2228 162.758 42.245C162.758 38.2672 165.336 35.4579 168.772 35.4579C169.685 35.4415 170.587 35.6644 171.387 36.1045C172.188 36.5446 172.859 37.1866 173.334 37.9665V35.7414H175.998ZM173.343 42.245C173.406 41.1879 173.047 40.1488 172.345 39.3561C171.643 38.5635 170.654 38.0822 169.597 38.0181H169.382C167.114 38.0181 165.508 39.7364 165.508 42.2536C165.508 44.7709 167.14 46.4978 169.382 46.4978C170.437 46.4932 171.446 46.0712 172.19 45.3241C172.934 44.577 173.351 43.5657 173.351 42.5114C173.351 42.434 173.351 42.3395 173.343 42.245Z"
        fill="white"
      />
      <path
        d="M160.201 40.5445V48.7578H157.529V41.0857C157.529 39.0754 156.627 38.01 154.66 38.01C152.391 38.01 151.154 39.5049 151.154 42.0136V48.7578H148.482V30.527H151.154V37.8726C151.59 37.1098 152.226 36.4812 152.994 36.0555C153.762 35.6298 154.633 35.4232 155.51 35.4584C158.423 35.4584 160.201 37.3743 160.201 40.5445Z"
        fill="white"
      />
      <path
        d="M145.668 42.2456C145.668 46.2234 143.09 49.0414 139.654 49.0414C138.749 49.0568 137.855 48.8388 137.059 48.4084C136.263 47.978 135.592 47.3498 135.109 46.5842V54.6773H132.437V35.7763H135.109V38.0015C135.586 37.221 136.259 36.5784 137.061 36.137C137.862 35.6956 138.765 35.4706 139.68 35.4842C143.116 35.4842 145.693 38.3022 145.693 42.28L145.668 42.2456ZM142.97 42.2456C142.97 39.7627 141.363 38.0101 139.087 38.0101C137.995 38.0592 136.965 38.5274 136.21 39.3171C135.455 40.1069 135.033 41.1573 135.033 42.2499C135.033 43.3425 135.455 44.3929 136.21 45.1827C136.965 45.9724 137.995 46.4406 139.087 46.4897C141.338 46.4897 142.97 44.7543 142.97 42.2456Z"
        fill="white"
      />
      <path d="M126.562 30.527H129.269V48.7578H126.562V30.527Z" fill="white" />
      <path
        d="M119.915 44.308H112.681L111.005 48.7583H108.162L114.614 32.2028H118.05L124.459 48.7755H121.624L119.906 44.3251L119.915 44.308ZM118.944 41.7735L116.315 34.8403L113.686 41.7735H118.944Z"
        fill="white"
      />
      <path
        d="M105.903 35.7423V48.7582H103.231V46.5846C102.752 47.3536 102.081 47.9847 101.284 48.4155C100.487 48.8464 99.592 49.0622 98.6863 49.0417C95.2498 49.0417 92.6724 46.2238 92.6724 42.246C92.6724 38.2682 95.2498 35.4588 98.6863 35.4588C99.5963 35.4471 100.494 35.6722 101.291 36.112C102.087 36.5518 102.756 37.1912 103.231 37.9675V35.7423H105.903ZM103.231 42.246C103.292 41.188 102.931 40.1489 102.228 39.3566C101.524 38.5642 100.535 38.0832 99.4767 38.019H99.2276C96.9508 38.019 95.3443 39.7373 95.3443 42.2546C95.3443 44.7718 96.9766 46.4987 99.2276 46.4987C99.7522 46.4998 100.272 46.3976 100.757 46.1979C101.242 45.9981 101.683 45.7048 102.055 45.3347C102.427 44.9645 102.722 44.5247 102.924 44.0405C103.126 43.5562 103.23 43.0369 103.231 42.5123C103.231 42.435 103.231 42.3405 103.231 42.246Z"
        fill="white"
      />
      <path d="M87.3123 35.7415H89.9842V48.7573H87.3037V35.7415H87.3123Z" fill="white" />
      <path
        d="M84.2029 30.527V48.7578H81.531V46.5842C81.0438 47.3493 80.3687 47.9769 79.5701 48.4071C78.7715 48.8372 77.876 49.0556 76.969 49.0413C73.4809 49.0413 70.9551 46.2234 70.9551 42.2456C70.9551 38.2678 73.5325 35.4498 76.969 35.4498C77.8855 35.44 78.7892 35.6664 79.5927 36.1073C80.3963 36.5482 81.0727 37.1887 81.5568 37.9671V30.527H84.2029ZM81.531 42.2456C81.5624 41.7214 81.4902 41.1962 81.3184 40.7C81.1467 40.2038 80.8788 39.7464 80.5301 39.3538C80.1814 38.9612 79.7587 38.6412 79.2862 38.4121C78.8138 38.183 78.3008 38.0493 77.7766 38.0186H77.5274C75.2593 38.0186 73.6442 39.7369 73.6442 42.2542C73.6442 44.7714 75.2765 46.4983 77.5274 46.4983C78.0528 46.5005 78.5734 46.3992 79.0596 46.1999C79.5457 46.0007 79.9877 45.7076 80.3604 45.3373C80.7331 44.967 81.0291 44.5269 81.2314 44.042C81.4337 43.5572 81.5385 43.0373 81.5396 42.5119C81.5396 42.4346 81.5396 42.3401 81.531 42.2456Z"
        fill="white"
      />
      <path
        d="M68.6404 42.9846H58.0559C58.0917 44.0063 58.5317 44.9721 59.2792 45.6696C60.0266 46.3671 61.0204 46.7394 62.0422 46.7046H62.1282C62.8271 46.7465 63.5233 46.5855 64.133 46.241C64.7427 45.8966 65.2398 45.3833 65.5647 44.763H68.3311C67.8977 46.0653 67.0502 47.1901 65.9178 47.9657C64.7854 48.7413 63.4304 49.1251 62.0594 49.0587C60.2571 49.0587 58.5286 48.3427 57.2541 47.0682C55.9797 45.7938 55.2637 44.0653 55.2637 42.2629C55.2637 40.4606 55.9797 38.732 57.2541 37.4576C58.5286 36.1831 60.2571 35.4672 62.0594 35.4672C66.252 35.4672 68.9325 38.7147 68.6404 43.0018V42.9846ZM58.2019 40.7938H65.7795C65.6205 39.9289 65.1589 39.1488 64.4773 38.5931C63.7957 38.0374 62.9386 37.7425 62.0594 37.7611C61.164 37.7387 60.2889 38.0294 59.5847 38.583C58.8806 39.1366 58.3916 39.9184 58.2019 40.7938Z"
        fill="white"
      />
      <path
        d="M52.2546 32.2028V48.7583H49.5742V35.8111L44.4623 43.5433H44.2046L39.1185 35.8111V48.7583H36.438V32.2028H39.591L44.3506 39.3508L49.1016 32.2028H52.2546Z"
        fill="white"
      />
      <path
        d="M29.9734 51.7223C29.6039 51.9487 29.1599 52.0198 28.7382 51.92C28.3165 51.8202 27.9513 51.5576 27.7225 51.1896C27.7225 51.1896 25.5145 47.7531 22.688 44.3165C23.3769 43.4313 24.0193 42.5108 24.6125 41.5587C26.7239 44.0985 28.6916 46.7545 30.5061 49.5143C30.7321 49.8825 30.803 50.3252 30.7031 50.7455C30.6033 51.1658 30.3409 51.5294 29.9734 51.7566V51.7223Z"
        fill="white"
      />
      <path
        d="M25.0532 37.358C25.5257 36.4216 26.1271 34.9697 26.4879 34.0847C27.2955 32.1345 24.2112 30.906 23.4552 32.882C22.1077 36.274 20.3352 39.4813 18.1801 42.4269C15.3535 46.8687 7.69864 52.2726 4.51126 45.4511C2.8789 42.152 2.99918 37.3924 4.769 35.0384C8.42032 31.052 14.4944 36.1467 18.3777 39.609C18.9768 38.6641 19.5218 37.686 20.01 36.6793C-1.58001 16.4725 -6.39116 50.6145 8.97016 51.9633C13.9875 51.9633 17.9739 48.1745 20.809 44.3858C22.433 42.1761 23.8535 39.8239 25.0532 37.358Z"
        fill="white"
      />
      <path
        d="M28.9756 27.7183C28.9756 28.0581 28.8748 28.3903 28.686 28.6729C28.4972 28.9555 28.2289 29.1757 27.9149 29.3057C27.6009 29.4358 27.2554 29.4698 26.9221 29.4035C26.5888 29.3372 26.2826 29.1736 26.0423 28.9333C25.802 28.693 25.6384 28.3868 25.5721 28.0535C25.5058 27.7202 25.5398 27.3747 25.6699 27.0607C25.7999 26.7467 26.0201 26.4784 26.3027 26.2896C26.5853 26.1008 26.9175 26 27.2573 26C27.713 26 28.1501 26.181 28.4723 26.5033C28.7946 26.8255 28.9756 27.2626 28.9756 27.7183Z"
        fill="white"
      />
    </svg>
  );
};

export default React.memo(Logo4);
