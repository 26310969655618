import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const Logo6 = (props: IIconProps) => {
  const { width = 176, height = 80, className } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 176 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50.0333 21.2724H43.1114V14.3152H39V32.9703H43.1114V24.9101H50.0333V32.9703H54.1447V14.3152H50.0333V21.2724Z"
        fill="white"
      />
      <path
        d="M116.173 24.9101H123.098V32.9703H127.206V14.3152H123.098V21.2724H116.173V14.3152H112.062V32.9703H116.173V24.9101Z"
        fill="white"
      />
      <path
        d="M101.113 32.9703H105.221V17.9529H110.191V14.3152H96.1426V17.9529H101.113V32.9703Z"
        fill="white"
      />
      <path
        d="M81.093 40.5568C81.093 39.4503 80.9057 38.5311 80.5274 37.8029C80.1491 37.0746 79.6401 36.4949 79.0002 36.0636C78.3603 35.6323 77.6073 35.3318 76.7483 35.155C75.8857 34.9783 74.9736 34.8899 74.0085 34.8899H67.0796V53.545H71.191V46.2732H74.1959C75.1787 46.2732 76.0943 46.1812 76.9357 45.9974C77.7806 45.8136 78.5088 45.5025 79.1239 45.0606C79.739 44.6222 80.2234 44.0319 80.5733 43.293C80.9233 42.5541 81.1001 41.6421 81.1001 40.5532L81.093 40.5568ZM76.5751 41.6491C76.4089 41.939 76.1897 42.1688 75.9175 42.335C75.6453 42.5011 75.3378 42.6213 74.9948 42.692C74.6519 42.7627 74.2984 42.7981 73.9272 42.7981H71.1875V38.372H73.2944C73.6798 38.372 74.0863 38.3932 74.507 38.4392C74.9277 38.4852 75.3095 38.5841 75.6524 38.7432C75.9953 38.9023 76.2746 39.125 76.4973 39.4149C76.7165 39.7048 76.8261 40.0866 76.8261 40.5603C76.8261 40.9987 76.7412 41.3628 76.5751 41.6527V41.6491Z"
        fill="white"
      />
      <path
        d="M108.424 53.5485H116.82V42.7415H116.873L123.247 53.5485H127.21V34.8934H123.098V45.7358H123.045L116.88 34.8934H112.708V53.0925L105.069 34.8934H101.417L94.1559 53.1774V49.7553H86.8946V34.8934H82.7832V53.5485H98.3804L99.8793 49.5962H106.872L108.424 53.5485ZM101.124 46.1176L103.312 40.3199L105.525 46.1176H101.124Z"
        fill="white"
      />
      <path
        d="M68.2465 32.9703H72.5559L74.0548 29.018H81.0474L82.5994 32.9703H87.0077V32.9986H98.0269V29.2053H91.1191V14.3435H87.0077V32.8112L79.2409 14.3152H75.5891L68.243 32.8112V29.1771H59.9106V25.3838H67.2921V21.5906H59.9106V18.112H67.7693V14.3187H55.8027V32.9738H68.2465V32.9703ZM77.491 19.7417L79.704 25.5394H75.3028L77.491 19.7417Z"
        fill="white"
      />
      <path
        d="M96.3371 58.2115C95.4851 57.3347 94.4599 56.6418 93.2686 56.1434C92.0737 55.6414 90.7657 55.3939 89.341 55.3939C87.9163 55.3939 86.6083 55.6449 85.4134 56.1434C84.2185 56.6454 83.1969 57.3347 82.3449 58.2115C81.4929 59.0917 80.8248 60.1275 80.3404 61.3224C79.8561 62.5173 79.6157 63.8076 79.6157 65.197C79.6157 66.5863 79.8561 67.8766 80.3404 69.0715C80.8248 70.2664 81.4894 71.3022 82.3449 72.1825C83.1969 73.0627 84.2185 73.7485 85.4134 74.2505C86.6083 74.7525 87.9163 75 89.341 75C90.7657 75 92.0737 74.749 93.2686 74.2505C94.4635 73.7485 95.4851 73.0592 96.3371 72.1825C97.1891 71.3057 97.8572 70.2664 98.3415 69.0715C98.8259 67.8766 99.0663 66.5863 99.0663 65.197C99.0663 63.8076 98.8259 62.5173 98.3415 61.3224C97.8572 60.1275 97.1891 59.0917 96.3371 58.2115ZM95.8775 68.0817C95.5452 68.9867 95.068 69.7715 94.4529 70.4396C93.8377 71.1078 93.0989 71.638 92.2398 72.034C91.3773 72.4299 90.4121 72.6279 89.341 72.6279C88.2698 72.6279 87.3012 72.4299 86.4421 72.034C85.5796 71.638 84.8442 71.1078 84.2291 70.4396C83.614 69.7715 83.1403 68.9867 82.8045 68.0817C82.4721 67.1767 82.3025 66.2151 82.3025 65.197C82.3025 64.1788 82.4686 63.2173 82.8045 62.3123C83.1368 61.4072 83.6105 60.6224 84.2291 59.9543C84.8442 59.2861 85.5831 58.7559 86.4421 58.3599C87.3012 57.964 88.2698 57.766 89.341 57.766C90.4121 57.766 91.3773 57.964 92.2398 58.3599C93.0989 58.7559 93.8377 59.2861 94.4529 59.9543C95.068 60.6224 95.5417 61.4072 95.8775 62.3123C96.2098 63.2173 96.3795 64.1788 96.3795 65.197C96.3795 66.2151 96.2134 67.1767 95.8775 68.0817Z"
        fill="white"
      />
      <path
        d="M111.627 70.1427H111.574L103.256 55.8641H100.29V74.5228H102.821V60.2477H102.87L111.295 74.5228H114.158V55.8641H111.627V70.1427Z"
        fill="white"
      />
      <path
        d="M118.351 66.1692H126.142V63.7971H118.351V58.2362H126.803V55.8641H115.819V74.5228H127.277V72.1507H118.351V66.1692Z"
        fill="white"
      />
      <path
        d="M128.405 56.1576H129.119V58.0347H129.43V56.1576H130.13V55.8748H128.405V56.1576Z"
        fill="white"
      />
      <path
        d="M131.742 57.5328L131.066 55.8748H130.579V58.0347H130.893V56.2212H130.904L131.632 58.0347H131.837L132.565 56.2212H132.576V58.0347H132.891V55.8748H132.42L131.742 57.5328Z"
        fill="white"
      />
      <path
        d="M122.773 5C120.03 5 117.81 7.22362 117.81 9.96338H131.303V23.4571C134.047 23.4571 136.267 21.2335 136.267 18.4938V5H122.773Z"
        fill="white"
      />
    </svg>
  );
};

export default React.memo(Logo6);
