import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const LeafIllustration = (props: IIconProps) => {
  const { width = 717, height = 588, className } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 717 588"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M358.06 586.58C555.259 586.58 715.12 471.701 715.12 329.99C715.12 188.279 555.259 73.3999 358.06 73.3999C160.861 73.3999 1 188.279 1 329.99C1 471.701 160.861 586.58 358.06 586.58Z"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M358.06 548.89C526.292 548.89 662.67 450.885 662.67 329.99C662.67 209.095 526.292 111.09 358.06 111.09C189.829 111.09 53.4502 209.095 53.4502 329.99C53.4502 450.885 189.829 548.89 358.06 548.89Z"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M358.06 512.65C498.44 512.65 612.24 430.87 612.24 329.99C612.24 229.11 498.44 147.33 358.06 147.33C217.68 147.33 103.88 229.11 103.88 329.99C103.88 430.87 217.68 512.65 358.06 512.65Z"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M358.06 474.96C469.472 474.96 559.79 410.055 559.79 329.99C559.79 249.925 469.472 185.02 358.06 185.02C246.648 185.02 156.33 249.925 156.33 329.99C156.33 410.055 246.648 474.96 358.06 474.96Z"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M358.06 440.17C441.062 440.17 508.35 391.817 508.35 332.17C508.35 272.523 441.062 224.17 358.06 224.17C275.057 224.17 207.77 272.523 207.77 332.17C207.77 391.817 275.057 440.17 358.06 440.17Z"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M358.06 431.47C427.919 431.47 484.55 390.773 484.55 340.57C484.55 290.367 427.919 249.67 358.06 249.67C288.202 249.67 231.57 290.367 231.57 340.57C231.57 390.773 288.202 431.47 358.06 431.47Z"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M358.06 430.02C417.668 430.02 465.99 395.295 465.99 352.46C465.99 309.625 417.668 274.9 358.06 274.9C298.452 274.9 250.13 309.625 250.13 352.46C250.13 395.295 298.452 430.02 358.06 430.02Z"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M442.25 400.98V400.97C442.25 400.97 442.25 400.96 442.26 400.96C442.26 400.96 442.26 400.96 442.27 400.96C444.33 398.18 446.17 395.32 447.71 392.33C451.6 384.85 453.72 376.76 453.72 368.31C453.72 330.34 410.89 299.56 358.05 299.56C305.21 299.56 262.38 330.34 262.38 368.31C262.38 376.76 264.5 384.85 268.39 392.32C269.94 395.33 271.78 398.23 273.88 401.01H273.89"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M277.34 403.94C284.62 376.68 318 356.08 358.06 356.08C398.12 356.08 431.49 376.68 438.77 403.94"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M283.1 408.26C295.99 387.75 324.71 373.48 358.06 373.48C391.41 373.48 420.12 387.75 433.01 408.26"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M292.66 414.16C307.68 400.01 331.39 390.88 358.06 390.88C384.73 390.88 408.43 400 423.45 414.16"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M307.25 420.9C321.23 412.99 338.88 408.27 358.06 408.27C377.24 408.27 394.88 412.99 408.86 420.9"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M334.45 428.15C341.93 426.53 349.85 425.67 358.06 425.67C366.27 425.67 374.19 426.53 381.67 428.15"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M358.06 586.58C555.259 586.58 715.12 471.701 715.12 329.99C715.12 188.279 555.259 73.3999 358.06 73.3999C160.861 73.3999 1 188.279 1 329.99C1 471.701 160.861 586.58 358.06 586.58Z"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M364.25 430.02L364.02 341C363.92 302.45 365.13 263.91 367.64 225.44L377.56 73.8"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M176.71 109.73L267.61 224.42C296.1 260.37 311.61 304.89 311.61 350.76V422.37"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M94.54 157.57L235.11 250.73C271.02 274.53 292.52 314.83 292.3 357.91L292.01 413.62"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M4.36035 296.65L176.97 311.48C236.16 315.64 267.19 346.65 267.99 390.16"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M7.16016 377.27L191.41 351.02C223.23 346.49 254.32 363.08 268.26 392.04L268.4 392.33C268.13 390.29 267.99 388.23 267.99 386.14C267.99 350.39 308.31 321.41 358.06 321.41C407.81 321.41 448.13 350.39 448.13 386.14C448.13 388.23 447.99 390.29 447.72 392.32"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M40.7305 447.42L211.31 384.58C229.91 377.73 250.76 381.42 265.87 394.25L276.16 402.99"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M104.24 510.39L234.61 414.09C249.76 402.9 270.03 401.54 286.54 410.59L298.44 417.12"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M185.99 554.69L276.24 433.81C283.94 423.49 297.15 418.91 309.59 422.23L317.33 424.3"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M284.35 580.79L324.79 438.85C326.34 433.42 330.76 429.29 336.29 428.11L337.26 359.95C338.14 298.04 326.98 236.55 304.38 178.91L266.66 82.6899"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M391.44 584.68L372.22 439.29C371.73 435.61 369.95 432.22 367.18 429.75"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M494.46 567.13L419.77 434.4C415.68 427.13 407.58 423.08 399.31 424.16"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M589.58 525.6L459.44 418.95C450.19 411.37 437.79 409.17 426.31 412.55"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M658.47 468.91L498.2 395.15C480.85 387.16 460.65 388.41 444.42 398.47"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M698.84 406.02L515.8 363.01C489.13 356.74 461.5 368.64 447.72 392.31"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M715.12 323.15L531.49 326.33C487.89 327.08 450.72 358.17 442.28 400.96"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M697.7 252.02L512.39 293.14C471.18 302.28 441.51 338.34 440.48 380.54L439.93 402.98C440.14 401.26 440.25 399.52 440.25 397.76C440.25 365.14 403.45 338.69 358.05 338.69C312.65 338.69 275.84 365.14 275.84 397.76C275.84 399.52 275.95 401.26 276.16 402.98L276.48 376.3C276.99 333.52 250.44 295.07 210.25 280.39L37.29 217.21"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M646.3 179.27L489.41 264.66C453.18 284.38 430.63 322.32 430.63 363.57V409.32"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M582.31 130.05L464.58 233.09C431.93 261.66 413.18 302.92 413.12 346.3L413.01 419.08"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M482.09 89.5901L419.73 213.29C400.18 252.07 390 294.89 390 338.32V426.71"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M439.56 275C447.015 275 453.06 268.956 453.06 261.5C453.06 254.044 447.015 248 439.56 248C432.104 248 426.06 254.044 426.06 261.5C426.06 268.956 432.104 275 439.56 275Z"
        fill="black"
      />
      <path
        d="M426.56 27C434.015 27 440.06 20.9558 440.06 13.5C440.06 6.04416 434.015 0 426.56 0C419.104 0 413.06 6.04416 413.06 13.5C413.06 20.9558 419.104 27 426.56 27Z"
        fill="black"
      />
      <path
        d="M482.56 102C498.3 102 511.06 89.2401 511.06 73.5C511.06 57.7599 498.3 45 482.56 45C466.819 45 454.06 57.7599 454.06 73.5C454.06 89.2401 466.819 102 482.56 102Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M241.06 202C260.942 202 277.06 185.882 277.06 166C277.06 146.118 260.942 130 241.06 130C221.177 130 205.06 146.118 205.06 166C205.06 185.882 221.177 202 241.06 202Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M319.56 118C329.225 118 337.06 110.165 337.06 100.5C337.06 90.835 329.225 83 319.56 83C309.895 83 302.06 90.835 302.06 100.5C302.06 110.165 309.895 118 319.56 118Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M312.06 313C319.239 313 325.06 307.18 325.06 300C325.06 292.82 319.239 287 312.06 287C304.88 287 299.06 292.82 299.06 300C299.06 307.18 304.88 313 312.06 313Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M389.56 353C396.463 353 402.06 347.404 402.06 340.5C402.06 333.596 396.463 328 389.56 328C382.656 328 377.06 333.596 377.06 340.5C377.06 347.404 382.656 353 389.56 353Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M337.56 411C345.568 411 352.06 404.508 352.06 396.5C352.06 388.492 345.568 382 337.56 382C329.551 382 323.06 388.492 323.06 396.5C323.06 404.508 329.551 411 337.56 411Z"
        fill="black"
      />
      <path
        d="M402.06 196C412.553 196 421.06 187.493 421.06 177C421.06 166.507 412.553 158 402.06 158C391.566 158 383.06 166.507 383.06 177C383.06 187.493 391.566 196 402.06 196Z"
        fill="#B9A07A"
      />
    </svg>
  );
};

export default React.memo(LeafIllustration);
