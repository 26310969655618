import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const TieIllustration = (props: IIconProps) => {
  const { width = 530, height = 400, className } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 530 400"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M414.433 249.051L221.119 268.852C213.529 269.627 206.934 263.675 206.934 256.042V75.5682C206.934 68.9582 211.935 63.4303 218.502 62.7576L411.815 42.9569C419.405 42.1818 426 48.1337 426 55.7674V236.226C426 242.836 420.999 248.364 414.433 249.037V249.051Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M238.714 333.38L256.786 264.38"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M386.572 251.237L416.143 333.38"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M252.098 193.068H252.083C247.876 193.068 244.464 196.48 244.464 200.687V223.267C244.464 227.475 247.876 230.886 252.083 230.886H252.098C256.306 230.886 259.717 227.475 259.717 223.267V200.687C259.717 196.48 256.306 193.068 252.098 193.068Z"
        fill="#B9A07A"
      />
      <path
        d="M295.453 169.772H295.438C291.23 169.772 287.819 173.184 287.819 177.392V218.104C287.819 222.312 291.23 225.723 295.438 225.723H295.453C299.661 225.723 303.072 222.312 303.072 218.104V177.392C303.072 173.184 299.661 169.772 295.453 169.772Z"
        fill="#B9A07A"
      />
      <path
        d="M336.121 144.927H336.106C331.898 144.927 328.487 148.338 328.487 152.546V215.136C328.487 219.344 331.898 222.755 336.106 222.755H336.121C340.329 222.755 343.74 219.344 343.74 215.136V152.546C343.74 148.338 340.329 144.927 336.121 144.927Z"
        fill="#B9A07A"
      />
      <path
        d="M376.79 86.343H376.775C372.567 86.343 369.156 89.7542 369.156 93.9621V209.856C369.156 214.064 372.567 217.475 376.775 217.475H376.79C380.998 217.475 384.409 214.064 384.409 209.856V93.9621C384.409 89.7542 380.998 86.343 376.79 86.343Z"
        fill="#B9A07A"
      />
      <path
        d="M245.087 163.867C245.087 163.867 363.79 144.593 383.327 10.667"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M368.074 20.0557L383.736 9L394.792 24.6622"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M233.125 161.059L155.707 238.463"
        stroke="black"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M233.124 205.591C257.718 205.591 277.656 185.654 277.656 161.059C277.656 136.465 257.718 116.528 233.124 116.528C208.53 116.528 188.592 136.465 188.592 161.059C188.592 185.654 208.53 205.591 233.124 205.591Z"
        fill="white"
        stroke="black"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M172.845 294.005C173.021 286.678 173.767 279.103 175.375 271.747C179.499 252.956 185.305 238.054 186.299 228.285C186.958 221.851 180.684 220.052 177.861 225.784C174.615 232.394 170.228 240.481 166.937 243.45C161.761 248.115 166.601 218.487 179.777 203.6C182.307 200.734 179.689 196.186 176.151 197.356C171.515 198.891 165.636 203.425 159.406 214.656C159.406 214.656 162.272 203.498 155.648 207.227C147.78 211.643 144.768 220.315 144.768 220.315C144.768 220.315 144.007 213.705 137.558 218.092C132.63 221.456 119.761 248.568 113.955 294.034H172.831L172.845 294.005Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M145.071 354.737C180.457 354.737 209.143 347.749 209.143 339.13C209.143 330.51 180.457 323.522 145.071 323.522C109.686 323.522 81 330.51 81 339.13C81 347.749 109.686 354.737 145.071 354.737Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M144.636 354.737C156.807 354.737 168.192 353.938 177.928 352.554C176.296 343.18 172.525 319.755 173 293.951H113.495C111.788 311.452 110.705 331.019 110.571 352.437C120.457 353.899 132.138 354.737 144.636 354.737Z"
        fill="black"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M241.52 155.02C230.318 155.02 221.251 164.101 221.251 175.289V200.851C225.009 201.977 229.002 202.592 233.126 202.592C244.24 202.592 254.33 198.219 261.788 191.097V175.303C261.788 164.101 252.707 155.035 241.52 155.035V155.02Z"
        fill="#B9A07A"
      />
    </svg>
  );
};

export default React.memo(TieIllustration);
