import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const DotsIcons = (props: IIconProps) => {
  const { width = 144, height = 144, className } = props;

  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 144 144"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="2" cy="2" r="2" fill="#000" />
      <circle cx="30" cy="2" r="2" fill="#000" />
      <circle cx="58" cy="2" r="2" fill="#000" />
      <circle cx="86" cy="2" r="2" fill="#000" />
      <circle cx="114" cy="2" r="2" fill="#000" />
      <circle cx="142" cy="2" r="2" fill="#000" />
      <circle cx="2" cy="30" r="2" fill="#000" />
      <circle cx="30" cy="30" r="2" fill="#000" />
      <circle cx="58" cy="30" r="2" fill="#000" />
      <circle cx="86" cy="30" r="2" fill="#000" />
      <circle cx="114" cy="30" r="2" fill="#000" />
      <circle cx="142" cy="30" r="2" fill="#000" />
      <circle cx="2" cy="58" r="2" fill="#000" />
      <circle cx="30" cy="58" r="2" fill="#000" />
      <circle cx="58" cy="58" r="2" fill="#000" />
      <circle cx="86" cy="58" r="2" fill="#000" />
      <circle cx="114" cy="58" r="2" fill="#000" />
      <circle cx="142" cy="58" r="2" fill="#000" />
      <circle cx="2" cy="86" r="2" fill="#000" />
      <circle cx="30" cy="86" r="2" fill="#000" />
      <circle cx="58" cy="86" r="2" fill="#000" />
      <circle cx="86" cy="86" r="2" fill="#000" />
      <circle cx="114" cy="86" r="2" fill="#000" />
      <circle cx="142" cy="86" r="2" fill="#000" />
      <circle cx="2" cy="114" r="2" fill="#000" />
      <circle cx="30" cy="114" r="2" fill="#000" />
      <circle cx="58" cy="114" r="2" fill="#000" />
      <circle cx="86" cy="114" r="2" fill="#000" />
      <circle cx="114" cy="114" r="2" fill="#000" />
      <circle cx="142" cy="114" r="2" fill="#000" />
      <circle cx="2" cy="142" r="2" fill="#000" />
      <circle cx="30" cy="142" r="2" fill="#000" />
      <circle cx="58" cy="142" r="2" fill="#000" />
      <circle cx="86" cy="142" r="2" fill="#000" />
      <circle cx="114" cy="142" r="2" fill="#000" />
      <circle cx="142" cy="142" r="2" fill="#000" />
    </svg>
  );
};

export default React.memo(DotsIcons);
