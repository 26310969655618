import React, { useCallback, useState } from 'react';

import { PrimaryButton } from '../components/Buttons/PrimaryButton';
import { ContentAnimate } from '../components/ContentAnimate/ContentAnimate';
import Layout from '../components/layout';
import ModalVideo from '../components/ModalVideo/ModalVideo';
import SEO from '../components/seo';
import ArrowIcon from '../images/ArrowLong';
import BottomDots from '../images/Index/bottom-dots';
import CheersIllustrationImage from '../images/Index/cheers-illustration';
import FactoryIllustrationImage from '../images/Index/factory-illustration';
import DotsIcons from '../images/Index/hero-dots';
import HeroSignatureIcon from '../images/Index/hero-signature';
import IntroIllustrationImage from '../images/Index/intro-illustration';
import LeafIllustrationImage from '../images/Index/leaf-illustration';
import PartnerLogo1 from '../images/Index/partners-logos/logo-1';
import PartnerLogo2 from '../images/Index/partners-logos/logo-2';
import PartnerLogo3 from '../images/Index/partners-logos/logo-3';
import PartnerLogo4 from '../images/Index/partners-logos/logo-4';
import PartnerLogo5 from '../images/Index/partners-logos/logo-5';
import PartnerLogo6 from '../images/Index/partners-logos/logo-6';
import PartnerLogo7 from '../images/Index/partners-logos/logo-7';
import PartnerLogo8 from '../images/Index/partners-logos/logo-8';
import PlayIcon from '../images/Index/Play';
import SquirrelIcon from '../images/Index/squirrel-icon';
import SquirrelIcon2 from '../images/Index/squirrel-icon2';
import TieIllustrationImage from '../images/Index/tie-illustration';

import style from '../styles/pages/index.scss';

import cn from 'classnames';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import Flickity from 'react-flickity-component';

const flickityOptions = {
  contain: true,
  freeScroll: true,
  initialIndex: 3,
  pageDots: false,
  prevNextButtons: false,
};

const IndexPage = (props: any) => {
  const { data } = props;

  const handleJoinUs = () => {
    window.open('https://trend-capital-holdings-inc.hirehive.com', '_blank');
  };

  const [isVideoModalOpen, setVideoModalOpen] = useState(false);

  const playVideo = () => {
    if (!isVideoModalOpen) {
      setVideoModalOpen(true);
    }
  };

  const handleModalVideoClose = useCallback(() => {
    if (isVideoModalOpen) {
      setVideoModalOpen(false);
    }
  }, [isVideoModalOpen]);

  return (
    <Layout>
      <SEO title="Home" />
      <section className={style.hero}>
        <div className="container">
          <div className="row">
            <div
              className={cn(
                'col-12 col-md-6 col-lg-7 offset-xl-1 order-md-last',
                style.imageSection
              )}
            >
              <ContentAnimate inViewAnimationClass={style.heroInView}>
                <HeroSignatureIcon className={cn('d-md-none', style.signatureIcon)} />
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.heroInView}>
                <Img
                  className={cn('lazy', style.heroBackgroundImage)}
                  fluid={data.heroBackground.childImageSharp.fluid}
                  alt="women"
                  loading="eager"
                />
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.heroInView}>
                <DotsIcons className={style.dotsIcons} />
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.heroInView}>
                <PrimaryButton className={style.playVideo} onClick={playVideo}>
                  <PlayIcon />
                </PrimaryButton>
              </ContentAnimate>
            </div>
            <div className="col-12 col-md-6 col-lg-5 col-xl-4">
              <ContentAnimate inViewAnimationClass={style.heroInView}>
                <HeroSignatureIcon className={cn('d-none d-md-block', style.signatureIcon)} />
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.heroInView}>
                <h1>Welcome</h1>
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.heroInView}>
                <p>
                  Trend is an ecosystem of marketing platforms that fuels consumer acquisition and
                  retention at scale.
                </p>
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.heroInView}>
                <a target="_blank" href="https://trend-capital-holdings-inc.hirehive.com">
                  Join Us <ArrowIcon />
                </a>
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.introInView}>
                <PrimaryButton className={style.primary} onClick={handleJoinUs}>
                  Join Us
                </PrimaryButton>
              </ContentAnimate>
            </div>
          </div>
        </div>
      </section>

      <section className={style.intro}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <ContentAnimate inViewAnimationClass={style.illustrationInView}>
                <IntroIllustrationImage className={style.illustrationImage} />
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.introInView}>
                <h5>
                  Based in the beautiful PNW, we're looking for talented individuals who are ready
                  to do their best work. Our entrepreneurial culture fosters creative thinking and
                  countless opportunities to drive your personal growth.
                </h5>
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.introInView}>
                <PrimaryButton onClick={handleJoinUs}>Join Us</PrimaryButton>
              </ContentAnimate>
            </div>
          </div>
        </div>
      </section>

      <div className={style.images}>
        <Flickity options={flickityOptions} static>
          <div className={style.carouselSlide}>
            <Img
              className={cn('lazy', style.image)}
              fluid={data.personImageOne.childImageSharp.fluid}
              alt="Men Image"
              loading="eager"
            />
          </div>
          <div className={style.carouselSlide}>
            <Img
              className={cn('lazy', style.image)}
              fluid={data.personImageTwo.childImageSharp.fluid}
              alt="Men Image"
              loading="eager"
            />
          </div>
          <div className={style.carouselSlide}>
            <Img
              className={cn('lazy', style.image)}
              fluid={data.personImageThree.childImageSharp.fluid}
              alt="Men Image"
              loading="eager"
            />
          </div>
          <div className={style.carouselSlide}>
            <Img
              className={cn('lazy', style.image)}
              fluid={data.personImageFour.childImageSharp.fluid}
              alt="Men Image"
              loading="eager"
            />
          </div>
          <div className={style.carouselSlide}>
            <Img
              className={cn('lazy', style.image)}
              fluid={data.personImageFive.childImageSharp.fluid}
              alt="Men Image"
              loading="eager"
            />
          </div>
          <div className={style.carouselSlide}>
            <Img
              className={cn('lazy', style.image)}
              fluid={data.personImageEight.childImageSharp.fluid}
              alt="Men Image"
              loading="eager"
            />
          </div>
          <div className={style.carouselSlide}>
            <Img
              className={cn('lazy', style.image)}
              fluid={data.personImageSeven.childImageSharp.fluid}
              alt="Men Image"
              loading="eager"
            />
          </div>
        </Flickity>
      </div>

      <section className={style.culture}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <ContentAnimate inViewAnimationClass={style.illustrationInView}>
                <FactoryIllustrationImage className={style.illustrationImage} />
              </ContentAnimate>
            </div>
            <div className="col-12 col-md-6">
              <ContentAnimate inViewAnimationClass={style.cultureInView}>
                <h2>We're a technology company</h2>
              </ContentAnimate>
            </div>
            <div className="col-12 col-md-6">
              <ContentAnimate inViewAnimationClass={style.cultureInView}>
                <p>
                  Trend has a proprietary tech ecosystem enabling us to achieve unparalleled results
                  for our partners.
                </p>
                <ul>
                  <li>
                    <p>Audience Targeting</p>
                  </li>
                  <li>
                    <p>Lead Processing</p>
                  </li>
                  <li>
                    <p>Tracking Platforms</p>
                  </li>
                  <li>
                    <p>Real-Time Bidding</p>
                  </li>
                  <li>
                    <p>Analytics</p>
                  </li>
                  <li>
                    <p>Data Warehouse</p>
                  </li>
                  <li>
                    <p>Segmentation Engine</p>
                  </li>
                  <li>
                    <p>Marketing Platforms</p>
                  </li>
                </ul>
              </ContentAnimate>
            </div>
          </div>
        </div>
      </section>

      <section className={style.projects}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
              <ContentAnimate inViewAnimationClass={style.projectsInView}>
                <h2>Our partners are everything</h2>
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.projectsInView}>
                <p>
                  We build direct-to-consumer marketing solutions for the largest lenders and
                  insurance carriers in the U.S.
                </p>
              </ContentAnimate>
            </div>
            <div className={cn('col-12', style.partnersLogos)}>
              <ContentAnimate inViewAnimationClass={style.projectsInView}>
                <PartnerLogo1 className={style.illustrationImage} />
                <PartnerLogo2 className={style.illustrationImage} />
                <PartnerLogo3 className={style.illustrationImage} />
                <PartnerLogo4 className={style.illustrationImage} />
                <PartnerLogo5 className={style.illustrationImage} />
                <PartnerLogo6 className={style.illustrationImage} />
                <PartnerLogo7 className={style.illustrationImage} />
                <PartnerLogo8 className={style.illustrationImage} />
              </ContentAnimate>
            </div>
          </div>
        </div>
      </section>

      <section className={style.positions}>
        <ContentAnimate inViewAnimationClass={style.positionsInView}>
          <SquirrelIcon className={style.squirrelIcon} />
        </ContentAnimate>
        <Img
          className={cn('lazy d-lg-none', style.workWithUsImage)}
          fluid={data.workWithUs.childImageSharp.fluid}
          alt="Men Image"
          loading="eager"
        />
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-10 offset-md-1 col-lg-5 col-xl-6 offset-lg-0">
              <ContentAnimate inViewAnimationClass={style.positionsInView}>
                <h2>What we do</h2>
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.positionsInView}>
                <p>
                  Trend is a marketing ecosystem that connects consumers to high-value products and
                  services.
                </p>
                <ul>
                  <li>
                    <p>100+ Advertising partners</p>
                  </li>
                  <li>
                    <p>100+ Marketing partners</p>
                  </li>
                  <li>
                    <p>Millions of consumers serviced</p>
                  </li>
                </ul>
              </ContentAnimate>
            </div>
          </div>
        </div>
      </section>

      <section className={style.benefits}>
        <div className="container">
          <div className="row">
            <div className={cn('col-12', style.row)}>
              <div className="row align-items-center">
                <div className="col-12 col-md-6">
                  <ContentAnimate inViewAnimationClass={style.illustrationInView}>
                    <LeafIllustrationImage className={style.illustrationImage} />
                  </ContentAnimate>
                </div>
                <div className="col-12 col-md-5 offset-md-1">
                  <ContentAnimate inViewAnimationClass={style.benefitsInView}>
                    <h3>
                      Lead-Gen <br />
                      Network & Technology
                    </h3>
                  </ContentAnimate>
                  <ContentAnimate inViewAnimationClass={style.benefitsInView}>
                    <p>
                      A direct lead-gen network with vast connections in personal finance and
                      insurance verticals — powered by proprietary technology.
                    </p>
                  </ContentAnimate>
                </div>
              </div>
            </div>
            <div className={cn('col-12', style.row)}>
              <div className="row align-items-center">
                <div className="col-12 col-md-6 offset-md-1 order-md-last">
                  <ContentAnimate inViewAnimationClass={style.illustrationInView}>
                    <TieIllustrationImage className={style.illustrationImage} />
                  </ContentAnimate>
                </div>
                <div className="col-12 col-md-5">
                  <ContentAnimate inViewAnimationClass={style.benefitsInView}>
                    <h3>
                      Marketing <br />
                      Team
                    </h3>
                  </ContentAnimate>
                  <ContentAnimate inViewAnimationClass={style.benefitsInView}>
                    <p>
                      Our internal team specializes in cross-channel marketing with a focus on
                      Facebook, Tiktok, Instagram, and Google.
                    </p>
                  </ContentAnimate>
                </div>
              </div>
            </div>
            <div className={cn('col-12', style.row)}>
              <div className="row align-items-center">
                <div className="col-12 col-md-6">
                  <ContentAnimate inViewAnimationClass={style.illustrationInView}>
                    <CheersIllustrationImage className={style.illustrationImage} />
                  </ContentAnimate>
                </div>
                <div className="col-12 col-md-5 offset-md-1">
                  <ContentAnimate inViewAnimationClass={style.benefitsInView}>
                    <h3>
                      Data <br />
                      Management
                    </h3>
                  </ContentAnimate>
                  <ContentAnimate inViewAnimationClass={style.benefitsInView}>
                    <p>
                      We enable brands to maximize their profits by managing their first-party
                      consumer data.
                    </p>
                  </ContentAnimate>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={style.bottomImages}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-4">
              <ContentAnimate inViewAnimationClass={style.bottomImagesInView}>
                <BottomDots className={style.bottomDotsImage} />
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.bottomImagesInView}>
                <Img
                  className={cn('lazy', style.pourImage)}
                  fluid={data.pourImage.childImageSharp.fluid}
                  alt="Pour Coffee Image"
                  loading="eager"
                />
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.bottomImagesInView}>
                <SquirrelIcon2 className={cn('d-none d-md-block', style.squirrelIcon2)} />
              </ContentAnimate>
            </div>
            <div className="col-12 col-lg-8">
              <ContentAnimate inViewAnimationClass={style.bottomImagesInView}>
                <Img
                  className={cn('lazy', style.bigBottomImage)}
                  fluid={data.bigBottomImage.childImageSharp.fluid}
                  alt="Big Man Image"
                  loading="eager"
                />
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.bottomImagesInView}>
                <SquirrelIcon2 className={cn('d-md-none', style.squirrelIcon2)} />
              </ContentAnimate>
            </div>
          </div>
        </div>
      </section>
      {isVideoModalOpen && (
        <ModalVideo isOpen onClose={handleModalVideoClose}>
          <video width="100%" controls autoPlay>
            <source src="../video/trend_capital_culture.mp4" type="video/mp4" />
            Your browser does not support HTML video.
          </video>
        </ModalVideo>
      )}
    </Layout>
  );
};
const DefaultLayoutSettingsQuery = (props: any) => {
  const renderDefaultLayoutSettings = (data: any) => <IndexPage data={data} {...props} />;

  return (
    <StaticQuery
      query={graphql`
        query IndexSection {
          heroBackground: file(relativePath: { eq: "Index/hero-background.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 860) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          workWithUs: file(relativePath: { eq: "Index/workWithUsBG.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1080) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          personImageOne: file(relativePath: { eq: "Index/image-1.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 380) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          personImageTwo: file(relativePath: { eq: "Index/image-2.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 560) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          personImageThree: file(relativePath: { eq: "Index/image-3.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 380) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          personImageFour: file(relativePath: { eq: "Index/image-4.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 560) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          personImageFive: file(relativePath: { eq: "Index/image-5.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 380) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          personImageSix: file(relativePath: { eq: "Index/image-6.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 560) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          personImageSeven: file(relativePath: { eq: "Index/image-7.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 380) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          personImageEight: file(relativePath: { eq: "Index/image-8.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 560) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          pourImage: file(relativePath: { eq: "Index/pour-image.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 460) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          bigBottomImage: file(relativePath: { eq: "Index/big-bottom-image.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 860) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={renderDefaultLayoutSettings}
    />
  );
};

export default DefaultLayoutSettingsQuery;
