import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const SquirrelIcon = (props: IIconProps) => {
  const { width = 457, height = 74, className } = props;

  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 457 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 59.402C21 40.84 77.8 3.182 145 1.06c84-2.652-37 83.093-36 70.718 1-12.376 78-38.895 116-28.287 20.447 5.708-4.5 31.823-6 28.287 1.667-14.438 27.6-42.43 118-38.895 90.4 3.536 117-8.545 119-15.027"
        stroke="#000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default React.memo(SquirrelIcon);
