import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const MaskIllustration = (props: IIconProps) => {
  const { width = 530, height = 461, className } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 530 461"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M438.602 358.724H397.29V429.955C415.504 419.479 429.731 406.738 438.602 392.539V358.724Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M438.603 238.491V392.539C445.25 381.884 448.877 370.411 448.877 358.455V204.407L438.603 238.491Z"
        fill="black"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M397.301 367.887H271.489V459.879C320.673 459.879 365.174 448.439 397.301 429.955V367.887Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M246.672 258.018L94.0776 264.119V358.455C94.0776 414.466 173.502 459.878 271.477 459.878V257.02C263.055 257.02 254.779 257.367 246.672 258.007V258.018Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M271.489 162.695C173.513 162.695 94.0889 208.108 94.0889 264.119C94.0889 320.13 173.513 365.543 271.489 365.543V162.684V162.695Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M271.489 162.695C173.513 162.695 94.0889 208.108 94.0889 264.119C94.0889 320.13 173.513 365.543 271.489 365.543V162.684V162.695Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M271.489 296.387V397.811C320.673 397.811 365.174 386.37 397.301 367.887L271.489 296.387Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M205.27 138.2V170.008L271.488 266.8V130.888C248.075 130.888 225.729 133.49 205.27 138.2Z"
        fill="#B9A07A"
        stroke="#B9A07A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M350.363 341.216L397.301 367.887V396.106C415.504 385.631 429.754 372.912 438.613 358.713L350.374 341.205L350.363 341.216Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M438.602 358.724L350.363 341.216L271.489 296.387V204.407L438.602 238.491V358.724Z"
        fill="black"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.7563 219.884H77.1103"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.7563 200.896H72.4389V219.884"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.789 169.088H50.7778C48.1979 169.088 46.1064 171.177 46.1064 173.754V183.411C46.1064 185.988 48.1979 188.077 50.7778 188.077H50.789C53.369 188.077 55.4604 185.988 55.4604 183.411V173.754C55.4604 171.177 53.369 169.088 50.789 169.088Z"
        fill="white"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.4604 173.765L46.1064 183.411"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.7563 188.077H77.1103"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.7563 169.088H72.4389V188.077"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.789 137.292H50.7778C48.1979 137.292 46.1064 139.38 46.1064 141.957V151.614C46.1064 154.191 48.1979 156.28 50.7778 156.28H50.789C53.369 156.28 55.4604 154.191 55.4604 151.614V141.957C55.4604 139.38 53.369 137.292 50.789 137.292Z"
        fill="white"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.4604 141.958L46.1064 151.614"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.7563 156.28H77.1103"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.7563 137.292H72.4389V156.28"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.789 105.495H50.7778C48.1979 105.495 46.1064 107.584 46.1064 110.161V119.818C46.1064 122.394 48.1979 124.483 50.7778 124.483H50.789C53.369 124.483 55.4604 122.394 55.4604 119.818V110.161C55.4604 107.584 53.369 105.495 50.789 105.495Z"
        fill="white"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.4604 110.161L46.1064 119.817"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.7563 124.483H77.1103"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.7563 105.495H72.4389V124.483"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.1064 92.6865H55.4604"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.1064 73.6985H50.7778V92.6867"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.1064 60.8789H55.4604"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.1064 41.8906H50.7778V60.8789"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.7563 29.0823H77.1103"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.7563 10.0942H72.4389V29.0825"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94.1001 200.896H94.0888C91.5089 200.896 89.4175 202.985 89.4175 205.562V215.218C89.4175 217.795 91.5089 219.884 94.0888 219.884H94.1001C96.68 219.884 98.7714 217.795 98.7714 215.218V205.562C98.7714 202.985 96.68 200.896 94.1001 200.896Z"
        fill="white"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M98.7714 205.562L89.4175 215.208"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M111.067 219.884H120.421"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M111.067 200.896H115.75V219.884"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94.1001 169.088H94.0888C91.5089 169.088 89.4175 171.177 89.4175 173.754V183.411C89.4175 185.988 91.5089 188.077 94.0888 188.077H94.1001C96.68 188.077 98.7714 185.988 98.7714 183.411V173.754C98.7714 171.177 96.68 169.088 94.1001 169.088Z"
        fill="white"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M98.7714 173.765L89.4175 183.411"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M111.067 188.077H120.421"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M111.067 169.088H115.75V188.077"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94.1001 137.292H94.0888C91.5089 137.292 89.4175 139.38 89.4175 141.957V151.614C89.4175 154.191 91.5089 156.28 94.0888 156.28H94.1001C96.68 156.28 98.7714 154.191 98.7714 151.614V141.957C98.7714 139.38 96.68 137.292 94.1001 137.292Z"
        fill="white"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M98.7714 141.958L89.4175 151.614"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M111.067 156.28H120.421"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M111.067 137.292H115.75V156.28"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M72.4389 73.6985H72.4277C69.8478 73.6985 67.7563 75.7874 67.7563 78.3642V88.021C67.7563 90.5978 69.8478 92.6867 72.4277 92.6867H72.4389C75.0189 92.6867 77.1103 90.5978 77.1103 88.021V78.3642C77.1103 75.7874 75.0189 73.6985 72.4389 73.6985Z"
        fill="white"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M77.1103 78.3643L67.7563 88.0098"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M89.4175 92.6865H98.7714"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M89.4175 73.6985H94.0888V92.6867"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M72.4389 41.8906H72.4277C69.8478 41.8906 67.7563 43.9795 67.7563 46.5564V56.2131C67.7563 58.7899 69.8478 60.8789 72.4277 60.8789H72.4389C75.0189 60.8789 77.1103 58.7899 77.1103 56.2131V46.5564C77.1103 43.9795 75.0189 41.8906 72.4389 41.8906Z"
        fill="white"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M77.1103 46.5676L67.7563 56.2131"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M89.4175 60.8789H98.7714"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M89.4175 41.8906H94.0888V60.8789"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94.1001 10.0942H94.0888C91.5089 10.0942 89.4175 12.1832 89.4175 14.76V24.4167C89.4175 26.9935 91.5089 29.0825 94.0888 29.0825H94.1001C96.68 29.0825 98.7714 26.9935 98.7714 24.4167V14.76C98.7714 12.1832 96.68 10.0942 94.1001 10.0942Z"
        fill="white"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M98.7714 14.76L89.4175 24.4168"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M137.411 200.896H137.4C134.82 200.896 132.729 202.985 132.729 205.562V215.218C132.729 217.795 134.82 219.884 137.4 219.884H137.411C139.991 219.884 142.082 217.795 142.082 215.218V205.562C142.082 202.985 139.991 200.896 137.411 200.896Z"
        fill="white"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M142.071 205.562L132.729 215.208"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M137.411 232.693H137.4C134.82 232.693 132.729 234.782 132.729 237.359V247.015C132.729 249.592 134.82 251.681 137.4 251.681H137.411C139.991 251.681 142.082 249.592 142.082 247.015V237.359C142.082 234.782 139.991 232.693 137.411 232.693Z"
        fill="white"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M142.071 237.358L132.729 247.015"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M154.379 219.884H163.733"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M154.379 200.896H159.062V219.884"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M137.411 169.088H137.4C134.82 169.088 132.729 171.177 132.729 173.754V183.411C132.729 185.988 134.82 188.077 137.4 188.077H137.411C139.991 188.077 142.082 185.988 142.082 183.411V173.754C142.082 171.177 139.991 169.088 137.411 169.088Z"
        fill="white"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M142.071 173.765L132.729 183.411"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M154.379 188.077H163.733"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M154.379 169.088H159.062V188.077"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M137.411 137.292H137.4C134.82 137.292 132.729 139.38 132.729 141.957V151.614C132.729 154.191 134.82 156.28 137.4 156.28H137.411C139.991 156.28 142.082 154.191 142.082 151.614V141.957C142.082 139.38 139.991 137.292 137.411 137.292Z"
        fill="white"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M142.071 141.958L132.729 151.614"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M159.062 137.292H159.05C156.47 137.292 154.379 139.38 154.379 141.957V151.614C154.379 154.191 156.47 156.28 159.05 156.28H159.062C161.641 156.28 163.733 154.191 163.733 151.614V141.957C163.733 139.38 161.641 137.292 159.062 137.292Z"
        fill="white"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M163.733 141.958L154.379 151.614"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M154.379 124.136H163.733"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M154.379 105.147H159.062V124.136"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94.1001 105.495H94.0888C91.5089 105.495 89.4175 107.584 89.4175 110.161V119.818C89.4175 122.394 91.5089 124.483 94.0888 124.483H94.1001C96.68 124.483 98.7714 122.394 98.7714 119.818V110.161C98.7714 107.584 96.68 105.495 94.1001 105.495Z"
        fill="white"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M98.7714 110.161L89.4175 119.817"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M111.067 124.483H120.421"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M111.067 105.495H115.75V124.483"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M115.75 73.6985H115.739C113.159 73.6985 111.067 75.7874 111.067 78.3642V88.021C111.067 90.5978 113.159 92.6867 115.739 92.6867H115.75C118.33 92.6867 120.421 90.5978 120.421 88.021V78.3642C120.421 75.7874 118.33 73.6985 115.75 73.6985Z"
        fill="white"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M120.421 78.3643L111.067 88.0098"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M132.729 92.6865H142.071"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M132.729 73.6985H137.4V92.6867"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M115.75 41.8906H115.739C113.159 41.8906 111.067 43.9795 111.067 46.5564V56.2131C111.067 58.7899 113.159 60.8789 115.739 60.8789H115.75C118.33 60.8789 120.421 58.7899 120.421 56.2131V46.5564C120.421 43.9795 118.33 41.8906 115.75 41.8906Z"
        fill="white"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M120.421 46.5676L111.067 56.2131"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M132.729 60.8789H142.071"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M132.729 41.8906H137.4V60.8789"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M173.356 219.884H182.699"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M173.356 200.896H178.027V219.884"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M173.356 188.077H182.699"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M173.356 169.088H178.027V188.077"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M130.045 124.483H139.388"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M130.045 105.495H134.716V124.483"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M151.695 92.6865H161.049"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M151.695 73.6985H156.366V92.6867"
        stroke="white"
        strokeWidth="18"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M438.602 358.724L350.363 341.216L271.489 296.387V204.407L438.602 238.491V358.724Z"
        fill="black"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.7563 219.884H77.1103"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.7563 200.896H72.4389V219.884"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.789 169.088H50.7778C48.1979 169.088 46.1064 171.177 46.1064 173.754V183.411C46.1064 185.988 48.1979 188.077 50.7778 188.077H50.789C53.369 188.077 55.4604 185.988 55.4604 183.411V173.754C55.4604 171.177 53.369 169.088 50.789 169.088Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.4604 173.765L46.1064 183.411"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.7563 188.077H77.1103"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.7563 169.088H72.4389V188.077"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.789 137.292H50.7778C48.1979 137.292 46.1064 139.38 46.1064 141.957V151.614C46.1064 154.191 48.1979 156.28 50.7778 156.28H50.789C53.369 156.28 55.4604 154.191 55.4604 151.614V141.957C55.4604 139.38 53.369 137.292 50.789 137.292Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.4604 141.958L46.1064 151.614"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.7563 156.28H77.1103"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.7563 137.292H72.4389V156.28"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.789 105.495H50.7778C48.1979 105.495 46.1064 107.584 46.1064 110.161V119.818C46.1064 122.394 48.1979 124.483 50.7778 124.483H50.789C53.369 124.483 55.4604 122.394 55.4604 119.818V110.161C55.4604 107.584 53.369 105.495 50.789 105.495Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.4604 110.161L46.1064 119.817"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.7563 124.483H77.1103"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.7563 105.495H72.4389V124.483"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.1064 92.6865H55.4604"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.1064 73.6985H50.7778V92.6867"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.1064 60.8789H55.4604"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.1064 41.8906H50.7778V60.8789"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.7563 29.0823H77.1103"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.7563 10.0942H72.4389V29.0825"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94.1001 200.896H94.0888C91.5089 200.896 89.4175 202.985 89.4175 205.562V215.218C89.4175 217.795 91.5089 219.884 94.0888 219.884H94.1001C96.68 219.884 98.7714 217.795 98.7714 215.218V205.562C98.7714 202.985 96.68 200.896 94.1001 200.896Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M98.7714 205.562L89.4175 215.208"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M111.067 219.884H120.421"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M111.067 200.896H115.75V219.884"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94.1001 169.088H94.0888C91.5089 169.088 89.4175 171.177 89.4175 173.754V183.411C89.4175 185.988 91.5089 188.077 94.0888 188.077H94.1001C96.68 188.077 98.7714 185.988 98.7714 183.411V173.754C98.7714 171.177 96.68 169.088 94.1001 169.088Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M98.7714 173.765L89.4175 183.411"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M111.067 188.077H120.421"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M111.067 169.088H115.75V188.077"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94.1001 137.292H94.0888C91.5089 137.292 89.4175 139.38 89.4175 141.957V151.614C89.4175 154.191 91.5089 156.28 94.0888 156.28H94.1001C96.68 156.28 98.7714 154.191 98.7714 151.614V141.957C98.7714 139.38 96.68 137.292 94.1001 137.292Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M98.7714 141.958L89.4175 151.614"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M111.067 156.28H120.421"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M111.067 137.292H115.75V156.28"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M72.4389 73.6985H72.4277C69.8478 73.6985 67.7563 75.7874 67.7563 78.3642V88.021C67.7563 90.5978 69.8478 92.6867 72.4277 92.6867H72.4389C75.0189 92.6867 77.1103 90.5978 77.1103 88.021V78.3642C77.1103 75.7874 75.0189 73.6985 72.4389 73.6985Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M77.1103 78.3643L67.7563 88.0098"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M89.4175 92.6865H98.7714"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M89.4175 73.6985H94.0888V92.6867"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M72.4389 41.8906H72.4277C69.8478 41.8906 67.7563 43.9795 67.7563 46.5564V56.2131C67.7563 58.7899 69.8478 60.8789 72.4277 60.8789H72.4389C75.0189 60.8789 77.1103 58.7899 77.1103 56.2131V46.5564C77.1103 43.9795 75.0189 41.8906 72.4389 41.8906Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M77.1103 46.5676L67.7563 56.2131"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M89.4175 60.8789H98.7714"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M89.4175 41.8906H94.0888V60.8789"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94.1001 10.0942H94.0888C91.5089 10.0942 89.4175 12.1832 89.4175 14.76V24.4167C89.4175 26.9935 91.5089 29.0825 94.0888 29.0825H94.1001C96.68 29.0825 98.7714 26.9935 98.7714 24.4167V14.76C98.7714 12.1832 96.68 10.0942 94.1001 10.0942Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M98.7714 14.76L89.4175 24.4168"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M137.411 200.896H137.4C134.82 200.896 132.729 202.985 132.729 205.562V215.218C132.729 217.795 134.82 219.884 137.4 219.884H137.411C139.991 219.884 142.082 217.795 142.082 215.218V205.562C142.082 202.985 139.991 200.896 137.411 200.896Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M142.071 205.562L132.729 215.208"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M137.411 232.693H137.4C134.82 232.693 132.729 234.782 132.729 237.359V247.015C132.729 249.592 134.82 251.681 137.4 251.681H137.411C139.991 251.681 142.082 249.592 142.082 247.015V237.359C142.082 234.782 139.991 232.693 137.411 232.693Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M142.071 237.358L132.729 247.015"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M154.379 219.884H163.733"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M154.379 200.896H159.062V219.884"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M137.411 169.088H137.4C134.82 169.088 132.729 171.177 132.729 173.754V183.411C132.729 185.988 134.82 188.077 137.4 188.077H137.411C139.991 188.077 142.082 185.988 142.082 183.411V173.754C142.082 171.177 139.991 169.088 137.411 169.088Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M142.071 173.765L132.729 183.411"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M154.379 188.077H163.733"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M154.379 169.088H159.062V188.077"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M137.411 137.292H137.4C134.82 137.292 132.729 139.38 132.729 141.957V151.614C132.729 154.191 134.82 156.28 137.4 156.28H137.411C139.991 156.28 142.082 154.191 142.082 151.614V141.957C142.082 139.38 139.991 137.292 137.411 137.292Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M142.071 141.958L132.729 151.614"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M159.062 137.292H159.05C156.47 137.292 154.379 139.38 154.379 141.957V151.614C154.379 154.191 156.47 156.28 159.05 156.28H159.062C161.641 156.28 163.733 154.191 163.733 151.614V141.957C163.733 139.38 161.641 137.292 159.062 137.292Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M163.733 141.958L154.379 151.614"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M154.379 124.136H163.733"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M154.379 105.147H159.062V124.136"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94.1001 105.495H94.0888C91.5089 105.495 89.4175 107.584 89.4175 110.161V119.818C89.4175 122.394 91.5089 124.483 94.0888 124.483H94.1001C96.68 124.483 98.7714 122.394 98.7714 119.818V110.161C98.7714 107.584 96.68 105.495 94.1001 105.495Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M98.7714 110.161L89.4175 119.817"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M111.067 124.483H120.421"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M111.067 105.495H115.75V124.483"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M115.75 73.6985H115.739C113.159 73.6985 111.067 75.7874 111.067 78.3642V88.021C111.067 90.5978 113.159 92.6867 115.739 92.6867H115.75C118.33 92.6867 120.421 90.5978 120.421 88.021V78.3642C120.421 75.7874 118.33 73.6985 115.75 73.6985Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M120.421 78.3643L111.067 88.0098"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M132.729 92.6865H142.071"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M132.729 73.6985H137.4V92.6867"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M115.75 41.8906H115.739C113.159 41.8906 111.067 43.9795 111.067 46.5564V56.2131C111.067 58.7899 113.159 60.8789 115.739 60.8789H115.75C118.33 60.8789 120.421 58.7899 120.421 56.2131V46.5564C120.421 43.9795 118.33 41.8906 115.75 41.8906Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M120.421 46.5676L111.067 56.2131"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M132.729 60.8789H142.071"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M132.729 41.8906H137.4V60.8789"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M173.356 219.884H182.699"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M173.356 200.896H178.027V219.884"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M173.356 188.077H182.699"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M173.356 169.088H178.027V188.077"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M130.045 124.483H139.388"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M130.045 105.495H134.716V124.483"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M151.695 92.6865H161.049"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M151.695 73.6985H156.366V92.6867"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M438.602 238.491C445.25 227.836 448.877 216.363 448.877 204.407C448.877 148.395 369.453 102.983 271.478 102.983V204.407L438.602 238.491Z"
        fill="black"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M209.649 144.425L271.488 232.311"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M279.36 206.01L438.602 238.491C442.723 231.885 445.688 224.965 447.339 217.809"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M438.603 238.491V358.724"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M285.054 207.177L271.489 233.388"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M309.679 212.19L271.489 285.956"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M334.283 217.215L288.344 305.965"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M358.908 222.24L309.365 317.921"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M383.512 227.253L330.397 329.866"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M408.137 232.278L351.609 341.463"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M432.752 237.291L376.28 346.353"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M438.614 278.542L400.962 351.254"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M438.613 331.088L425.644 356.155"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default React.memo(MaskIllustration);
