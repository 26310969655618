import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const SquirrelIcon = (props: IIconProps) => {
  const { width = 162, height = 142, className } = props;

  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 166 142"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 104.1c1.389-29.704 46.53-74.489 100.697-84.932 80.559-15.531 79.718 79.354 22.918 93.286C59.335 128.466 26.102 40.12 85.03 8.029c56.252-30.633 88.197 45.248 12.5 104.425-73.501 57.461-90.974 2.088-26.389-45.948 59.09-43.949 109.031-6.959 59.724 74.494"
        stroke="#fff"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default React.memo(SquirrelIcon);
